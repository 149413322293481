/* Recursive waiter function */
const waitUntil = (prop, resolve, reject, time = 50, iteration = 0) => {
	/* If prop is available in window */
	if (prop in window) {
		resolve();
	} else if (iteration < 20) {
		/* If it's not, wait for a small while, then try again */
		setTimeout(() => {
			/* Increase time by 1.35x to balance fast response and slow loading */
			waitUntil(prop, resolve, reject, time * 1.35, iteration++);
		}, time);
	} else {
		/* Reject the Promise if after 20 attempts nothing became available */
		reject(`${prop} did not become available after 20 attempts`);
	}
};

/* Promisify waiting for a window prop to become available */
export default function whenAvailable(prop) {
	/* If it's not an array, make it into one */
	const props = Array.isArray(prop) ? prop : [prop];

	/* Create a Promise for each prop in the array */
	return Promise.all(props.map(prop => new Promise((resolve, reject) => {
		waitUntil(prop, resolve, reject);
	})));
}
