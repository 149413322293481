/* Default error handler */
export default function errorHandler(error) {
	if ('rg4js' in window && error instanceof Error) {
		window.rg4js('send', {
			error,
			tags: ['handled_promise_rejection'],
		});
	} else {
		console.error(error);
	}
}
