import errorHandler from 'Utils/errorHandler.js';
import whenAvailable from 'Utils/whenAvailable.js';

/* eslint-disable new-cap */

whenAvailable('Osano')
	.then(() => {
		/* Centre the dialog for jurisdictions that require interaction */
		window.Osano('onUiChanged', (component, stateChange) => {
			/* Only if we're currently showing the consent dialog */
			if (component === 'dialog' && stateChange === 'show') {
				/* Add heading if we haven't already */
				const heading = document.querySelectorAll('.osano-cm-dialog__content > h1');
				if (heading.length === 0) {
					/* Create heading */
					const newHeading = document.createElement('h1');
					newHeading.className = 'osano-cm-dialog__header';

					/* Add text */
					const newHeadingText = document.createTextNode('Cookies');
					newHeading.append(newHeadingText);

					/* Add to dialog */
					document.querySelector('.osano-cm-dialog__content').prepend(newHeading);
				}

				/* Select buttons inside the Osano consent dialog */
				const buttons = document.querySelectorAll('.osano-cm-dialog__buttons .osano-cm-button');
				const hasButtons = buttons.length > 0;

				/* If the dialog has buttons, we'll put it front and centre */
				if (hasButtons) {
					/* Replace positioning to center */
					const dialog = document.querySelector('.osano-cm-dialog');
					dialog.className = dialog.className.replace(/osano-cm-dialog--position_[a-z_-]+/gi, 'osano-cm-dialog--position_center');

					/* Enable dimming */
					document.documentElement.classList.add('is-cc-intrusive');

					/* Prevent scrolling */
					document.documentElement.classList.add('is-frozen');
				}
			}
		});

		/* Consent preferences are saved */
		window.Osano('onConsentSaved', () => {
			/* Re-enable scrolling */
			document.documentElement.classList.remove('is-frozen');
		});

		/* Wait for the rest of the document to load */
		(function (fn) {
			if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
				fn();
			} else {
				document.addEventListener('DOMContentLoaded', fn);
			}
		})(() => {
			/* Open Osano cookie preferences by link */
			const elements = document.querySelectorAll('.osano-cookie-preferences');
			Array.prototype.forEach.call(elements, element => {
				if ('Osano' in window && window.Osano.cm.mode !== 'debug') {
					element.addEventListener('click', event => {
						event.preventDefault();
						window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
						return false;
					});
				} else {
					element.parentNode.classList.add('is-hidden');
				}
			});
		});
	})
	.catch(error => {
		errorHandler(new Error(error));
	});

/* eslint-enable new-cap */
